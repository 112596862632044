import jcf from 'jcf';

export default function jcfInit() {
  var customSelect = jQuery('select');
  var customCheckbox = jQuery('input[type="checkbox"]');
  var customRadio = jQuery('input[type="radio"]');

  // all option see https://github.com/w3co/jcf
  jcf.setOptions('Select', {
    wrapNative: false,
    wrapNativeOnMobile: false,
    fakeDropInBody: false,
    maxVisibleItems: 6
  });

  jcf.setOptions('Checkbox', {});

  jcf.setOptions('Radio', {});

  // init only after option
  jcf.replace(customSelect);
  jcf.replace(customCheckbox);
  jcf.replace(customRadio);
}
