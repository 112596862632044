export default function loginForm() {
  const formWrapper = document.querySelector('.login-form');
  const inputs = formWrapper.querySelectorAll('input[id^=input]');
  const emailInput = inputs[0];
  const passwordInput = inputs[1];
  const emailInputContainer = emailInput.parentElement;
  const passwordInputContainer = passwordInput.parentElement;

  emailInput.placeholder = 'Email or Login';
  emailInputContainer.classList.add('ginput_container_email');

  passwordInput.placeholder = 'Password';
  passwordInputContainer.classList.add('ginput_container_password');
}
