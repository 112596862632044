export default function fixedHeader() {
  const header = document.querySelector('.header');
  const headerHeight = header.clientHeight;
  const FIXED_CLASS = 'header--fixed';

  function windowScrollHandler() {
    if (window.scrollY > headerHeight) {
      header.classList.add(FIXED_CLASS);
    }

    if (window.scrollY === 0) {
      header.classList.remove(FIXED_CLASS);
    }
  }

  window.addEventListener('scroll', windowScrollHandler);
}
