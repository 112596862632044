import Swiper, { Navigation, Thumbs, EffectFade } from 'swiper';
import debounce from '../helpers/debounce';

export default function reviewSlider() {
  const OPTIONS = {
    SLIDER: {
      modules: [Navigation, Thumbs, EffectFade],
      effect: 'fade',
      autoHeight: true,
      breakpoints: {
        1200: {
          allowTouchMove: false
        }
      },
      navigation: {
        nextEl: '.review-slider_arrow-next',
        prevEl: '.review-slider_arrow-prev'
      }
    },
    THUMBS: {
      spaceBetween: 20,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 2
        },
        640: {
          slidesPerView: 3
        }
      }
    }
  };

  const thumbsSldier = new Swiper('.review-slider_thumbs .swiper', OPTIONS.THUMBS);
  const slider = new Swiper('.review-slider_quotes', {
    ...OPTIONS.SLIDER,
    thumbs: {
      swiper: thumbsSldier
    }
  });

  const updateDelay = 300;
  const sliderUpdate = debounce(() => {
    slider.update();
  }, updateDelay);

  window.addEventListener('resize', sliderUpdate);
}
