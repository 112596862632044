import storageService from './storage.js';

/**
 * Service class to manage couponse.
 */
export default function couponService() {
  /**
   * Apply the default coupon to the storage service.
   */
  function applyDefaultCoupon() {
    storageService().coupon = {
      coupon_code: '30OffUtiOrder',
      discount_amount: 30,
      name: '30% Off',
      type: 'Percent',
    };
  }

  return {
    applyDefaultCoupon,
  };
}
