import { show, hide, toggle } from 'slidetoggle';

export default function accordion(accordionSelector, triggerSelector, activeClass) {
  const accordion = document.querySelectorAll(accordionSelector);
  const triggers = document.querySelectorAll(triggerSelector);

  const ANIMATION_OPTIONS = {
    MILLISECONDS: 200,
    TRANSITION: 'ease-in-out'
  };

  function slideUp(content) {
    hide(content, ANIMATION_OPTIONS);
  }

  function slideToggle(content) {
    toggle(content, ANIMATION_OPTIONS);
  }

  accordion.forEach(item => {
    const isClosedOnStart = item.classList.contains('js-acco-closed-on-start');

    if (!isClosedOnStart) {
      const firstTrigger = item.querySelector(triggerSelector);
      const firstContent = firstTrigger.nextElementSibling;

      firstTrigger.classList.add(activeClass);
      show(firstContent, ANIMATION_OPTIONS);
    }
  });

  triggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      const isNotOpened = !trigger.classList.contains(activeClass);

      if (isNotOpened) {
        triggers.forEach(trigger => {
          const content = trigger.nextElementSibling;

          trigger.classList.remove(activeClass);
          slideUp(content);
        });
      }

      const content = trigger.nextElementSibling;

      trigger.classList.toggle(activeClass);
      slideToggle(content);
    });
  });
}
