export default function videoPlayer() {
  const wrapper = document.querySelector('.video-player');
  const poster = wrapper.querySelector('.video-player_poster');
  const iframe = wrapper.querySelector('iframe');

  if (!iframe) return;

  function handleClick() {
    iframe.src = `${iframe.src}?autoplay=1&mute=1`;
    poster.style.display = 'none';
  }

  wrapper.addEventListener('click', handleClick);
}
