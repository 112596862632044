import accordion from './accordion';
import tabs from './tabs';
import scrollToElement from './scrollToElement';

export default function toc() {
  const wrapper = document.querySelector('.toc_body');
  const textContainers = document.querySelectorAll('.text-container');

  textContainers.forEach(container => {
    const linksWrapper = document.createElement('div');
    const item = document.createElement('div');
    const h2 = container.querySelectorAll('h2');
    const category = document.createElement('button');

    h2.forEach(item => {
      const link = document.createElement('a');

      link.textContent = item.textContent;
      linksWrapper.append(link);
    });

    item.classList.add('toc_item');
    linksWrapper.classList.add('toc_links');
    category.classList.add('toc_category');

    category.textContent = container.dataset.tocTitle;

    item.append(category);
    item.append(linksWrapper);
    wrapper.append(item);
  });

  const links = document.querySelectorAll('.toc_links a');
  const h2 = document.querySelectorAll('.text-container h2');

  tabs('.toc_category', '.tab-item');
  accordion('.toc_body', '.toc_category', 'toc_category--active');
  scrollToElement(links, h2);
}
