export default function tabs(triggerSelector, contentSelector) {
  const tabsElements = {
    trigger: document.querySelectorAll(triggerSelector),
    content: document.querySelectorAll(contentSelector)
  };

  const STATE_CLASS = {
    ACTIVE: 'tab-item--visible',
    HIDDEN: 'tab-item--hidden'
  };

  const TRIGGER_ACTIVE_CLASS = 'js-tab-visible';

  function hideContent() {
    tabsElements.content.forEach(item => {
      item.classList.remove(STATE_CLASS.ACTIVE);
      item.classList.add(STATE_CLASS.HIDDEN);
    });
  }

  function showContent(i = 0) {
    tabsElements.content[i].classList.remove(STATE_CLASS.HIDDEN);
    tabsElements.content[i].classList.add(STATE_CLASS.ACTIVE);
  }

  function removeActiveClass() {
    tabsElements.trigger.forEach(item => {
      item.classList.remove(TRIGGER_ACTIVE_CLASS);
    });
  }

  function addActiveClass(i = 0) {
    tabsElements.trigger[i].classList.add(TRIGGER_ACTIVE_CLASS);
  }

  removeActiveClass();
  hideContent();
  addActiveClass();
  showContent();

  tabsElements.trigger.forEach((item, i) => {
    item.addEventListener('click', () => {
      const isTabbAlreadyActive = item.classList.contains(TRIGGER_ACTIVE_CLASS);

      if (isTabbAlreadyActive) return;
      removeActiveClass();
      addActiveClass(i);

      hideContent();
      showContent(i);
    });
  });
}
