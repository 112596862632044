import '../libs/jquery.star-rating-svg.min';

export default function starsRating() {
  jQuery('.stars').starRating({
    initialRating: 4,
    activeColor: '#B46397',
    starShape: 'rounded',
    strokeWidth: 0,
    emptyColor: 'lightgray',
    useGradient: false,
    starSize: 24,
    readOnly: true
  });
}
