import isElementExist from './helpers/isElementExist.js';
import headerMenu from './modules/headerMenu';
import fixedHeader from './modules/fixedHeader';
import setHeaderVariable from './modules/setHeaderVariable';
import accordion from './modules/accordion';
import videoPlayer from './modules/videoPlayer';
import medicationChoice from './modules/medicationChoice';
import toc from './modules/toc';
import loginForm from './modules/loginForm';

import jcfInit from './modules/jcfInit';
import reviewSlider from './modules/reviewSlider';
import starsRating from './modules/starsRating';
import AOS from 'aos';

window.addEventListener('DOMContentLoaded', () => {
  isElementExist('.header', () => {
    fixedHeader();
    setHeaderVariable();
  });
  isElementExist('.header_menu', headerMenu);
  isElementExist('.accordion', () => accordion('.accordion', '.accordion_button', 'accordion_button--active'));
  isElementExist('.toc', toc);
  isElementExist('.review-slider', reviewSlider);
  isElementExist('.video-player', videoPlayer);
  isElementExist('.medications-box', medicationChoice);
  isElementExist('.stars', starsRating);
  isElementExist('.login-form', loginForm);
  jcfInit();
  AOS.init({
    once: true,
    duration: 800
  });
});
