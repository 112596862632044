import debounce from '../helpers/debounce';
import setRootProperty from '../helpers/setRootProperty';

export default function setHeaderVariable() {
  const header = document.querySelector('.header');
  const headerVariableName = 'header-height';
  const DELAY = 300;

  const setHeaderHeightVariable = debounce(() => {
    const headerHeight = header.offsetHeight;
    setRootProperty(headerVariableName, headerHeight);
  }, DELAY);

  window.addEventListener('load', setHeaderHeightVariable);
  window.addEventListener('resize', setHeaderHeightVariable);
}
