import storageService from '../services/storage.js';
import couponService from '../services/coupon.js';

export default function medicationChoice() {
  const medicationContainer = document.querySelector('.medications-box');
  const medicationList = medicationContainer.querySelector('.list-main');
  const medicationItems = medicationList.querySelectorAll('.list-main .list-main_item');
  const confirmMedicationBtn = medicationContainer.querySelector('.js-details-link');
  const BTN_DISABLED_CLASS = 'button--disabled';
  const ITEM_CLASS = '.list-main_item';
  const ACTIVE_CLASS = 'list-main_item--active';
  let preselectedMedicationElement;
  medicationList.addEventListener('click', handleMedicationListClick);
  confirmMedicationBtn.addEventListener('click', handleConfirmMedicationClick);
  onInit();

  /**
   * Initializes component
   */
  function onInit() {
    loadMedicationItem();
  }

  /**
   * Loads selected medication list item
   */
  function loadMedicationItem() {
    const medicationItem = storageService().tests.length ? findSelectedMedicationListItem() : medicationItems[0];
    selectMedication(medicationItem);
  }

  /**
   * Adds selection to list item
   *
   * @param item {Element} List item
   */
  function selectMedication(item) {
    preselectedMedicationElement = item;
    item.classList.add(ACTIVE_CLASS);
  }

  /**
   * Removes selection from all list items
   */
  function removeSelection() {
    medicationItems.forEach((item) => {
      item.classList.remove(ACTIVE_CLASS);
    });
  }

  /**
   * Handles click on li item
   *
   * @param event {Event} Click event
   */
  function handleMedicationListClick(event) {
    const target = event.target;
    const listItem = target.closest(ITEM_CLASS);

    if (!listItem) return;

    removeSelection();
    selectMedication(listItem);
    confirmMedicationBtn.classList.remove(BTN_DISABLED_CLASS);
  }

  /**
   * Handles click on 'Next' btn item
   *
   * @param event {Event} Click event
   */
  function handleConfirmMedicationClick(event) {
    event.preventDefault();
    storageService().tests = [
      { name: preselectedMedicationElement.dataset.name, price: preselectedMedicationElement.dataset.price * 100 },
    ];
    storageService().setUtiTreatmentType();
    couponService().applyDefaultCoupon();
    window.location.href = '/uti-pharmacy';
  }

  /**
   * Finds selected medication list item
   *
   * @returns {Element} Selected medication list item
   */
  function findSelectedMedicationListItem() {
    return Array.from(medicationItems).find((item) => item.dataset.name === storageService().tests[0].name);
  }
}
