export default function headerMenu() {
  const wrapper = document.querySelector('.header_menu');
  const trigger = document.querySelector('.menu-trigger');

  const ACTIVE_CLASS = {
    MENU: 'header_menu--active',
    TRIGGER: 'menu-trigger--active'
  };

  function toggleMenu() {
    wrapper.classList.toggle(ACTIVE_CLASS.MENU);
    trigger.classList.toggle(ACTIVE_CLASS.TRIGGER);

    if (document.body.classList.contains('_lock')) {
      document.body.classList.remove('_lock');
    } else {
      document.body.classList.add('_lock');
    }
  }

  function hideMenu() {
    wrapper.classList.remove(ACTIVE_CLASS.MENU);
    trigger.classList.remove(ACTIVE_CLASS.TRIGGER);
  }

  trigger.addEventListener('click', toggleMenu);
  window.addEventListener('resize', hideMenu);
}
