export default function scrollToElement(trigger, targetElement) {
  const header = document.querySelector('.header');
  const headerHeight = header.clientHeight;
  const EXTRA_OFFSET = 30;

  trigger.forEach((item, i) => {
    const elementOffsetTop = targetElement[i].getBoundingClientRect().top;
    const offsetTop = elementOffsetTop - headerHeight - EXTRA_OFFSET;

    function scrollTo() {
      window.scroll({
        left: 0,
        top: offsetTop,
        behavior: 'smooth'
      });
    }

    item.addEventListener('click', scrollTo);
  });
}
